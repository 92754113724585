html {
    height: 100% !important;
}
body.cover-themed {
    height: 100% !important;
    background-color: #f5f5f5;
    display: -ms-flexbox;
    display: flex;
    color: #0c71b8;
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);

    .cover-container {
        max-width: 42em;
    }
    
    .cover {
        /*
        * Cover
        */
        padding: 0 1.5rem;
        background-color: rgba(0,0,0,.2);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        .btn-lg {
            padding: .75rem 1.25rem;
            font-weight: 700;
        }
    }

    /*
 * Header
 */
.masthead {
    margin-bottom: 2rem;
  }
  
  .masthead-brand {
    margin-bottom: 0;
    color: #39aa34;
  }
  
  .nav-masthead .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(12,113,184, .5);
    background-color: transparent;
    border-bottom: .25rem solid transparent;
  }
  
  .nav-masthead .nav-link:hover,
  .nav-masthead .nav-link:focus {
    border-bottom-color: rgba(12,113,184, .25);
  }
  
  .nav-masthead .nav-link + .nav-link {
    margin-left: 1rem;
  }
  
  .nav-masthead .active {
    color: #0c71b8;
    border-bottom-color: #0c71b8;
  }
  
  @media (min-width: 48em) {
    .masthead-brand {
      float: left;
    }
    .nav-masthead {
      float: right;
    }
  }
    
    .mastfoot {
        /*
        * Footer
        */
        color: rgba(12,113,184, .5);
    }

        /* Links */
    a,
    a:focus,
    a:hover {
    color:  #0c71b8;
    }

    /* Custom default button */
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:focus {
    color: #333;
    text-shadow: none; /* Prevent inheritance from `body` */
    background-color: #fff;
    border: .05rem solid #fff;
    }
}
